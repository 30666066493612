import React, { useState, useEffect } from 'react';
import loadingGif from '../assets/slothGif.gif';
import useAdminCheck from './AuthContext/useAdminCheck';
import { useAuth } from './AuthContext/AuthContext';

function WidgetThree() {
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const { currentUser } = useAuth();
  const isAdmin = useAdminCheck(currentUser?.email);

  const getDaysAway = (preAuthDate) => {
    if (!preAuthDate) return null;
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const expiryDate = new Date(preAuthDate);
    expiryDate.setHours(0, 0, 0, 0);
    const diff = (expiryDate - currentDate) / (1000 * 3600 * 24);
    return Math.round(diff); // Ensure integer
  };

  const sortOrders = (orders) => {
    return orders.sort((a, b) => {
      const aFillPriority = a.FillPriority || 5;
      const bFillPriority = b.FillPriority || 5;

      // FillPriority sorting
      if (aFillPriority === 1 && bFillPriority !== 1) return -1;
      if (aFillPriority !== 1 && bFillPriority === 1) return 1;
      if (aFillPriority === 2 && bFillPriority !== 2 && bFillPriority !== 1) return -1;
      if (aFillPriority !== 2 && bFillPriority === 2 && aFillPriority !== 1) return 1;

      // Prepay sorting
      const aIsPrepay = a["Terms"] && a["Terms"].toUpperCase() === "PREPAY";
      const bIsPrepay = b["Terms"] && b["Terms"].toUpperCase() === "PREPAY";

      if (aIsPrepay && !bIsPrepay) return -1;
      if (!aIsPrepay && bIsPrepay) return 1;

      if (aIsPrepay && bIsPrepay) {
        const aDaysAway = getDaysAway(a["PreAuth Expiry Date"]);
        const bDaysAway = getDaysAway(b["PreAuth Expiry Date"]);

        if (aDaysAway !== null && bDaysAway === null) return -1;
        if (aDaysAway === null && bDaysAway !== null) return 1;
        if (aDaysAway !== null && bDaysAway !== null) return aDaysAway - bDaysAway;
      }

      return 0;
    });
  };

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });

    const sortedData = [...orders].sort((a, b) => {
      let aValue = a[key];
      let bValue = b[key];

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        aValue = aValue.toLowerCase();
        bValue = bValue.toLowerCase();
      }

      if (key === 'Current User') {
        aValue = aValue || '';
        bValue = bValue || '';
      }

      if (!isNaN(Date.parse(aValue)) && !isNaN(Date.parse(bValue))) {
        aValue = new Date(aValue);
        bValue = new Date(bValue);
      } else if (!isNaN(aValue) && !isNaN(bValue)) {
        aValue = parseFloat(aValue);
        bValue = parseFloat(bValue);
      }

      if (aValue < bValue) return direction === 'ascending' ? -1 : 1;
      if (aValue > bValue) return direction === 'ascending' ? 1 : -1;

      return 0;
    });

    setOrders(sortedData);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        let response = await fetch(process.env.REACT_APP_API_INDEPENDENT_1_PRIMARY);
        if (!response.ok) throw new Error('Network response was not ok');
        let data = await response.json();

        if (data && data.data && data.data.length > 0) {
          setOrders(sortOrders(data.data));
        } else {
          throw new Error('No data returned');
        }
      } catch (error) {
        console.error('Fetch failed:', error);
        setOrders([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 120000);
    return () => clearInterval(intervalId);
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (orders.length === 0) {
    return <img src={loadingGif} alt="Loading" className='loadingGif' />;
  }

  const calculateTotal = () => {
    return orders.reduce((acc, order) => {
      return acc + parseFloat(order["Sub Total after all Discounts"] || 0);
    }, 0).toFixed(2);
  };

  const rowCount = orders.length;

  return (
    <div className='widget one flex-item'>
      <div>
        <div className="Header">
          <h2>Independents in progress</h2>
        </div>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('SO#')}>SO# {sortConfig.key === 'SO#' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('PO#')}>PO# {sortConfig.key === 'PO#' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Customer Code')}>Customer Code {sortConfig.key === 'Customer Code' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Order Date')}>Order Date {sortConfig.key === 'Order Date' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Order Status')}>Order Status {sortConfig.key === 'Order Status' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Ship Name')}>Ship Name {sortConfig.key === 'Ship Name' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Requested Ship Date')}>Requested Ship Date {sortConfig.key === 'Requested Ship Date' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              {isAdmin && <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Sub Total after all Discounts')}>Sub Total after all Discounts {sortConfig.key === 'Sub Total after all Discounts' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>}
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Fill %')}>Fill % {sortConfig.key === 'Fill %' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Current User')}>Current User {sortConfig.key === 'Current User' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('No# of Detail lines')}>No# of Detail lines {sortConfig.key === 'No# of Detail lines' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Percentage of Cons')}>Percentage of Cons {sortConfig.key === 'Percentage of Cons' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('PreAuth Expiry Date')}>PreAuth Expiry Date {sortConfig.key === 'PreAuth Expiry Date' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Terms')}>Terms {sortConfig.key === 'Terms' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
              <th style={{ border: '1px solid black', textAlign: 'left', cursor: 'pointer' }} onClick={() => handleSort('Priority')}>Priority {sortConfig.key === 'Priority' ? (sortConfig.direction === 'ascending' ? '🔼' : '🔽') : null}</th>
            </tr>
          </thead>
          <tbody>
            {orders.map((order, index) => {
              const daysAway = getDaysAway(order["PreAuth Expiry Date"]);
              let rowStyle = {};
              let priorityText = "";

              // Apply FillPriority styles
              if (order.FillPriority === 1) {
                rowStyle = { backgroundColor: 'red', color: 'white', fontWeight: 'bold' }; // Rush orders
                priorityText = "Rush";
              } else if (order.FillPriority === 2) {
                rowStyle = { backgroundColor: 'darkgreen', color: 'white', fontWeight: 'bold' }; // Priority 2
                priorityText = "Priority 2";
              }

              // Apply PREPAY and daysAway styling
              const isPrepay = order["Terms"] && order["Terms"].toUpperCase() === "PREPAY";
              if (isPrepay && daysAway !== null && order.FillPriority !== 1) {
                if (daysAway < 0) {
                  rowStyle = { backgroundColor: 'red', color: 'black', fontWeight: 'bold' }; // Expired
                } else if (daysAway >= 0 && daysAway <= 2) {
                  rowStyle = { backgroundColor: 'red', color: 'black', fontWeight: 'bold' }; // Urgent
                } else if (daysAway === 3) {
                  rowStyle = { backgroundColor: 'orange' }; // Orange
                } else if (daysAway === 4) {
                  rowStyle = { backgroundColor: 'yellow' }; // Yellow
                }
              }

              return (
                <tr key={index} style={rowStyle}>
                  <td style={{ border: '1px solid black' }}>{order["SO#"]}</td>
                  <td style={{ border: '1px solid black' }}>{order["PO#"]}</td>
                  <td style={{ border: '1px solid black' }}>{order["Customer Code"]}</td>
                  <td style={{ border: '1px solid black' }}>{order["Order Date"]}</td>
                  <td style={{ border: '1px solid black' }}>{order["Order Status"]}</td>
                  <td style={{ border: '1px solid black' }}>{order["Ship Name"]}</td>
                  <td style={{ border: '1px solid black' }}>{order["Requested Ship Date"]}</td>
                  {isAdmin && <td style={{ border: '1px solid black' }}>${order["Sub Total after all Discounts"]}</td>}
                  <td style={{ border: '1px solid black' }}>{order["Fill %"]}</td>
                  <td style={{ border: '1px solid black' }}>{order["Current User"] || ''}</td>
                  <td style={{ border: '1px solid black' }}>{order["No# of Detail lines"]}</td>
                  <td style={{ border: '1px solid black' }}>{order["Percentage of Cons"]}</td>
                  <td style={{ border: '1px solid black' }}>{order["PreAuth Expiry Date"]}</td>
                  <td style={{ border: '1px solid black' }}>{order["Terms"]}</td>
                  <td style={{ border: '1px solid black' }}>{priorityText}</td>
                </tr>
              );
            })}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ border: '1px solid black', textAlign: 'center' }}>Total SO's: {rowCount}</td>
              <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan={isAdmin ? 6 : 7}></td>
              {isAdmin && (
                <>
                  <td style={{ border: '1px solid black', textAlign: 'center' }}>${calculateTotal()}</td>
                  <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="7"></td>
                </>
              )}
              {!isAdmin && <td style={{ border: '1px solid black', textAlign: 'center' }} colSpan="8"></td>}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

export default WidgetThree;
